import React, { useMemo } from 'react';
import * as figbird from 'figbird';
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import { Application, feathers } from '@feathersjs/feathers';
import authentication, { AuthenticationClient } from '@feathersjs/authentication-client';
import PQueue from 'p-queue';
import { stripSlashes } from '@feathersjs/commons';
import { useKeycloak } from '@react-keycloak-fork/web';
import { KeycloakInstance } from 'keycloak-js';
import config from './config';

declare module '@feathersjs/authentication-client' {
  export interface AuthenticationClientOptions {
    keycloak: KeycloakInstance
  }
}

class Authentication extends AuthenticationClient {
  public token: string = '';

  async setAccessToken(token: string) {
    this.token = token;
  }

  async getAccessToken() {
    return this.options.keycloak.token || '';
  }

  async reAuthenticate(force?: boolean, strategy?: string) {
    await this.options.keycloak.updateToken(15);

    if (force || this.options.keycloak.token !== this.token) {
      const result = await super.reAuthenticate(true, strategy);
      return result;
    }
    return this.app.get('authentication');
  }
}

const authenticationQueue = new PQueue({ concurrency: 1 });

const authenticationHook = (app: Application) => app.hooks({
  before: async ({ path }) => {
    if (stripSlashes(app.authentication.options.path) === path) return;
    if (!app.authentication.options.keycloak.authenticated) return;
    await authenticationQueue.add(async () => app.reAuthenticate());
  },
});

const apiUrl = config.api.url.endsWith('.') ? `${window.location.protocol}//${(config.api as any).baseURL || (config.api as any).url}${window.location.hostname}` : config.api.url;

const FeathersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const client = useMemo(
    () => initialized && feathers()
      .configure(socketio(io(apiUrl as string)))
      .configure(authentication({ Authentication, jwtStrategy: 'oidc', keycloak }))
      .configure(authenticationHook),
    [initialized, keycloak, Authentication],
  );

  return (
    <>
      {!client && <div />}
      {!!client && (
      <figbird.Provider feathers={client}>
        {children}
      </figbird.Provider>
      )}
    </>
  );
};

export default FeathersProvider;
