/* eslint-disable max-classes-per-file */
import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { useEvent } from 'react-use';

class DropzoneDropTarget extends EventTarget {}
class DropzoneDropEvent extends Event {
  public args: Parameters<NonNullable<DropzoneOptions['onDrop']>>;

  constructor(...args: Parameters<NonNullable<DropzoneOptions['onDrop']>>) {
    super('drop');
    this.args = args;
  }
}

class DropzoneDragEnterTarget extends EventTarget {}
class DropzoneDragEnterEvent extends Event {
  public args: Parameters<NonNullable<DropzoneOptions['onDragEnter']>>;

  constructor(...args: Parameters<NonNullable<DropzoneOptions['onDragEnter']>>) {
    super('dragenter');
    this.args = args;
  }
}

declare interface DropzoneDropTarget {
  addEventListener(event: 'drop', listener: null | EventListenerObject | ((event: DropzoneDropEvent) => any)): void;
}
declare interface DropzoneDragEnterTarget {
  addEventListener(event: 'dragenter', listener: null | EventListenerObject | ((event: DropzoneDragEnterEvent) => any)): void;
}

interface UploadContextResult {
  open: () => any
  dropTarget: DropzoneDropTarget
  dragEnterTarget: DropzoneDragEnterTarget
}

const UploadContext = createContext<UploadContextResult>({
  dragEnterTarget: new DropzoneDragEnterTarget(),
  dropTarget: new DropzoneDropTarget(),
  open: () => {},
});

interface DropProviderProps {
  children?: ReactNode
}

const DropProvider: React.FC<DropProviderProps> = ({ children = null }) => {
  const dropTarget = useMemo(() => new DropzoneDropTarget(), []);
  const dragEnterTarget = useMemo(() => new DropzoneDragEnterTarget(), []);

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    noClick: true,
    noKeyboard: true,
    onDragEnter: (...args) => dragEnterTarget.dispatchEvent(new DropzoneDragEnterEvent(...args)),
    onDrop: (...args) => dropTarget.dispatchEvent(new DropzoneDropEvent(...args)),
  });

  const value = useMemo(() => ({
    dragEnterTarget,
    dropTarget,
    open,
  }), [
    open,
    dropTarget,
    dragEnterTarget,
  ]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...getRootProps()}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <UploadContext.Provider value={value}>
        {children}
      </UploadContext.Provider>
    </div>
  );
};

interface UseDropProps {
  onDrop?: DropzoneOptions['onDrop'];
  onDragEnter?: DropzoneOptions['onDragEnter'];
}

export const useDrop = (props: UseDropProps) => {
  const { dropTarget, dragEnterTarget, ...rest } = useContext(UploadContext);
  const { onDrop = () => {}, onDragEnter = () => {} } = props;
  useEvent('drop', (event) => onDrop(...event.args), dropTarget);

  useEvent('dragenter', (event) => onDragEnter(...event.args), dragEnterTarget);
  return rest;
};
export default DropProvider;
