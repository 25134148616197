import React, { createContext, useContext, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import axios, { AxiosInstance } from 'axios';
import config from './config';

interface SearchContextResult {
  search?: AxiosInstance
}

const SearchContext = createContext<SearchContextResult>({
  search: undefined,
});

const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const search = useMemo(
    () => {
      if (initialized) {
        const instance = axios.create(config.search);
        instance.interceptors.request.use(
          async (request) => {
            await keycloak.updateToken(15);
            // if (!request.headers) request.headers = {};
            Object.assign(request.headers, {
              Authorization: `Bearer ${keycloak.token}`,
            });
            return request;
          },
        );
        return instance;
      }
      return undefined;
    },
    [initialized, keycloak, config],
  );

  const value = useMemo(() => ({ search }), [
    search,
  ]);
  return (
    <>
      {!initialized && (
        <div />
      )}
      <SearchContext.Provider value={value}>
        {children}
      </SearchContext.Provider>
    </>
  );
};

export const useSearch = () => useContext(SearchContext);

export default SearchProvider;
