import React from 'react';
import {
  Button, Menu, Row, Col, Select, Divider, Input, Space, Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  useLocation, useNavigate,
  Link,
} from 'react-router-dom';
import { useKeycloak } from '@react-keycloak-fork/web';
import { PlusOutlined } from '@ant-design/icons';
// import { useFind, useUnpaginated } from './figbirdx';
import { useFind } from 'figbird';
import { useSelectedPool } from './SelectedPoolProvider';
import useUnpaginated from './useUnpaginated';

interface Pool {
  id: string
  name: string
}
const dropdownRender = (menu: React.ReactNode) => (
  <>
    {menu}
    <Divider style={{ margin: '8px 0' }} />
    <Space style={{ padding: '0 8px 8px' }}>
      <Tooltip title="Not supported yet">
        <Input.Search placeholder="Create pool" enterButton={<PlusOutlined />} disabled />
      </Tooltip>
    </Space>
  </>
);

const NavBar: React.FC<{ }> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const registerAction = React.useCallback(() => keycloak.register(), []);
  const loginAction = React.useCallback(() => keycloak.login(), []);
  const logoutAction = React.useCallback(() => keycloak.logout({ redirectUri: `${window.location.origin}/` }), []);
  const accountAction = React.useCallback(() => keycloak.accountManagement(), []);

  const { selectedPool, setSelectedPoolId } = useSelectedPool();
  const poolsResult = useFind<Pool>('pool', {});

  const items: MenuProps['items'] = [{
    key: '/document',
    label: 'Documents',
    onClick: () => navigate('/document'),
  }, {
    key: '/tag',
    label: 'Tags',
    onClick: () => navigate('/tag'),
  }];

  const pools = useUnpaginated(poolsResult);

  return (
    <Row wrap={false} style={{ overflowX: 'auto' }}>
      <Col>
        <div
          style={{
            color: '#fff',
            fontSize: '200%',
            lineHeight: 'initial',
            margin: '16px',
          }}
        >
          <Link
            to="/"
            style={{
              color: 'inherit',
            }}
          >
            AnyDoc
          </Link>
        </div>
      </Col>
      <Col flex="auto" style={{ minWidth: '46px' }}>
        <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]} items={items} />
      </Col>
      <Col style={{ margin: '0 16px', maxWidth: 'initial' }}>
        <Row gutter={[16, 16]} wrap={false}>
          {!!keycloak.authenticated && selectedPool && poolsResult.data && (
            <Col>
              <Select
                value={selectedPool.id}
                style={{ width: '120px' }}
                popupMatchSelectWidth={false}
                onChange={(id) => setSelectedPoolId(id)}
                dropdownRender={dropdownRender}

              >
                {pools.map((pool) => (
                  <Select.Option key={pool.id} value={pool.id}>
                    {pool.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
          {!keycloak.authenticated && (
            <Col>
              <Button
                type="primary"
                onClick={loginAction}
              >
                Login
              </Button>
            </Col>
          )}
          {!!keycloak.authenticated && (
            <Col>
              <Button
                type="primary"
                onClick={logoutAction}
              >
                Logout
              </Button>
            </Col>
          )}
          {!keycloak.authenticated && (
            <Col>
              <Button
                onClick={registerAction}
              >
                Register
              </Button>
            </Col>
          )}
          {!!keycloak.authenticated && (
            <Col>
              <Button
                onClick={accountAction}
              >
                {keycloak.idTokenParsed?.name}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default NavBar;
