import { useKeycloak } from '@react-keycloak-fork/web';
import { useFind } from 'figbird';
import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import useUnpaginated from './useUnpaginated';

interface User {
  id: string
}
interface UserContextResult {
  user: any
}

const UserContext = createContext<UserContextResult>({
  user: undefined,
});

interface UserProviderProps {
  children?: ReactNode
}

const UserProvider: React.FC<UserProviderProps> = ({ children = null }) => {
  const { keycloak } = useKeycloak();
  const oidcId = keycloak.idTokenParsed?.sub;

  const userResult = useFind<User>('user', { query: { oidcId }, skip: !oidcId });

  const users = useUnpaginated(userResult.data);
  const value = useMemo(() => ({
    user: users.find(Boolean),
  }), [
    users,
  ]);

  // if (userResult.error) throw userResult.error;
  // useLoading(!!userResult.isFetching);

  return (
    <>
      {userResult.isFetching && (
        <div />
      )}
      <UserContext.Provider value={value}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
