import merge from 'lodash/merge';
import defaultConfig from './default.yml';
import stagingConfig from './staging.yml';
import productionConfig from './production.yml';

if (window.location.hostname === process.env.REACT_APP_STAGING_HOSTNAME) {
  merge(defaultConfig, stagingConfig, { env: 'staging' });
} else if (window.location.hostname === process.env.REACT_APP_PRODUCTION_HOSTNAME) {
  merge(defaultConfig, productionConfig, { env: 'production' });
}
export default defaultConfig;
