import { useMemo } from 'react';
import { UseFindResult } from 'figbird';

export function useUnpaginated<T extends object>(data: UseFindResult<T> | T[] | T | undefined | null) {
  return useMemo(() => {
    if (!data) return [];
    if (Array.isArray(data)) return data;
    if ('data' in data && data.data) return data.data;
    if ('data' in data) return [];
    return [data];
  }, [data]);
}

export default useUnpaginated;
