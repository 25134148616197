import React from 'react';

import { FallbackProps } from 'react-error-boundary';
import { Alert, Col, Row } from 'antd';

const ErrorPage: React.FC<FallbackProps> = ({ error }) => (
  <Row style={{ minHeight: '100%', minWidth: '100%' }} align="middle" justify="center">
    <Col flex="0 0 auto">
      <Alert
        message={error.name}
        description={error.message}
        type="error"
      />

    </Col>
  </Row>
);

export default ErrorPage;
