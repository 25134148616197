import React from 'react';
import {
  Layout,
} from 'antd';

import NavBar from './NavBar';

interface MainProps {
  children: React.ReactNode
  pending?: boolean
}

const Main: React.FC<MainProps> = ({ children, pending = false }) => (
  <Layout style={{ minHeight: '100vh', minWidth: '320px' }}>
    <Layout.Header style={{
      padding: 0, position: 'fixed', width: '100%', zIndex: 1,
    }}
    >
      {!pending && (
      <NavBar />
      )}

    </Layout.Header>
    <Layout.Content style={{ display: 'flex', margin: '64px 0 0 0' }}>
      {children}
    </Layout.Content>
  </Layout>
);

export default Main;
