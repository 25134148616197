import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import { promisify } from 'es6-promisify';
import config from './config';

const locize = new Locize(config.locize);

// if (config.env !== 'test') i18n.use(locize);

export const initialized = promisify((cb) => i18n.init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // react: { wait: true },
  saveMissing: true,
}, cb))();

export default i18n;
