import { useFind, useGet } from 'figbird';
import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useLocalStorage } from 'react-use';
import useUnpaginated from './useUnpaginated';

interface Pool {
  id: string
  name: string
}
interface SelectedPoolContextResult {
  selectedPool: any
  setSelectedPoolId: (id: string) => any
}

const SelectedPoolContext = createContext<SelectedPoolContextResult>({
  selectedPool: undefined,
  setSelectedPoolId: () => {},
});

interface SelectedPoolProviderProps {
  children?: ReactNode
}

const SelectedPoolProvider: React.FC<SelectedPoolProviderProps> = ({ children = null }) => {
  const [selectedPoolId, setSelectedPoolId] = useLocalStorage<any>('selectedPool', null);
  const poolResult = useGet<Pool>('pool', selectedPoolId, { skip: !selectedPoolId });
  const pools = useUnpaginated(poolResult.data);
  const pool = useMemo(() => pools.find(Boolean), [pools]);
  const defaultPoolResult = useFind<Pool>('pool', { query: { isDefault: true }, realtime: 'merge', skip: false });
  const defaultPools = useUnpaginated(defaultPoolResult.data);
  const defaultPool = useMemo(() => defaultPools.find(Boolean), [defaultPools]);

  const value = useMemo(() => ({
    selectedPool: defaultPool,
    setSelectedPoolId: (id: string) => setSelectedPoolId(id),
  }), [
    defaultPool,
    setSelectedPoolId,
  ]);

  // if (poolResult.error) throw poolResult.error;
  return (
    <>
      {!!defaultPoolResult.isFetching && (
      <div />
      )}
      <SelectedPoolContext.Provider value={value}>
        {children}
      </SelectedPoolContext.Provider>
    </>
  );
};

export const useSelectedPool = () => useContext(SelectedPoolContext);

export default SelectedPoolProvider;
