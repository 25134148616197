import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router';
import KeycloakProvider from './KeycloakProvider';
import FeathersProvider from './FeathersProvider';
import LoadingPage from './LoadingPage';
import i18n from './i18n';
import Main from './Layout';
import ErrorPage from './ErrorPage';
import SelectedPoolProvider from './SelectedPoolProvider';
import DropProvider from './DropProvider';
import UploadProvider from './UploadProvider';
import SearchProvider from './SearchProvider';
import UserProvider from './UserProvider';
// import 'antd/dist/antd.css';

const DocumentPage = React.lazy(() => import('./DocumentPage'));
const DocumentEditPage = React.lazy(() => import('./DocumentEditPage'));
const TagPage = React.lazy(() => import('./TagPage'));
const IndexPage = React.lazy(() => import('./IndexPage'));

const App: React.FC<{}> = () => {
  const location = useLocation();
  return (
    <KeycloakProvider>
      <FeathersProvider>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <React.Suspense fallback={<Main pending><LoadingPage /></Main>}>
            <UserProvider>
              <UploadProvider>
                <SearchProvider>
                  <I18nextProvider i18n={i18n}>
                    <SelectedPoolProvider>
                      <DropProvider>
                        <Main>
                          <Routes>
                            <Route
                              path="/document"
                              element={(
                                <ErrorBoundary key={location.pathname} FallbackComponent={ErrorPage}>
                                  <React.Suspense fallback={<LoadingPage />}>
                                    <DocumentPage />
                                  </React.Suspense>
                                </ErrorBoundary>
                          )}
                            />
                            <Route
                              path="/"
                              element={(
                                <ErrorBoundary key={location.pathname} FallbackComponent={ErrorPage}>
                                  <React.Suspense fallback={<LoadingPage />}>
                                    <IndexPage />
                                  </React.Suspense>
                                </ErrorBoundary>
                          )}
                            />
                            <Route
                              path="/document/:documentId"
                              element={(
                                <ErrorBoundary key={location.pathname} FallbackComponent={ErrorPage}>
                                  <React.Suspense fallback={<LoadingPage />}>
                                    <DocumentEditPage />
                                  </React.Suspense>
                                </ErrorBoundary>
                          )}
                            />
                            <Route
                              path="/tag"
                              element={(
                                <ErrorBoundary key={location.pathname} FallbackComponent={ErrorPage}>
                                  <React.Suspense fallback={<LoadingPage />}>
                                    <TagPage />
                                  </React.Suspense>
                                </ErrorBoundary>
                          )}
                            />
                          </Routes>
                        </Main>
                      </DropProvider>
                    </SelectedPoolProvider>
                  </I18nextProvider>
                </SearchProvider>
              </UploadProvider>
            </UserProvider>
          </React.Suspense>
        </ErrorBoundary>
      </FeathersProvider>
    </KeycloakProvider>
  );
};
export default App;
