import React, { useMemo } from 'react';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak-fork/web';
import config from './config';

const KeycloakProvider: React.FC<{ children: React.ReactNode }> = React.memo(({ children }) => {
  const keycloak = useMemo(() => Keycloak(config.keycloak), []);
  const keycloakInitOptions: KeycloakInitOptions = useMemo(() => ({
    checkLoginIframe: false,
    silentCheckSsoFallback: false,
    // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.htm`,
  }), []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      autoRefreshToken={false}
    >
      {children}
    </ReactKeycloakProvider>
  );
});

export default KeycloakProvider;
