import React from 'react';
import { Spin, Row, Col } from 'antd';

const LoadingPage: React.FC<{}> = () => (
  <Row style={{ minHeight: '100%', minWidth: '100%' }} align="middle" justify="center">
    <Col flex="0 0 auto">
      <Spin tip="Loading..." />

    </Col>
  </Row>
);

export default LoadingPage;
